
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { toTitleCase, trimStringWithSpace } from '../utils/utils';

interface PageTitleProps {
  pageTitle: string,
  description?: string,
  name?: string,
  type?: string,
  canonical?: string,
  imageurl?: string | null | undefined,
}


const PageTitle: React.FC<PageTitleProps> = ({ pageTitle, description, name, type, canonical, imageurl }) => {


  return (
    <Helmet>
      { /* Standard metadata tags */}
      <title>{toTitleCase(pageTitle)}</title>
      {description &&
        <meta name='description' content={trimStringWithSpace(description, 150)} />}
      {(canonical && canonical.length > 0) &&
        <link rel="canonical" href={canonical} />}
      { /* End standard metadata tags */}
      { /* Facebook tags */}
      <meta property="og:type" content={type ? type : "article"} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description ? trimStringWithSpace(description, 150) : trimStringWithSpace(pageTitle, 150)} />
      <meta property="og:site_name" content="WindyAI" />
      {imageurl ? <meta property="og:image" content={imageurl} /> : ""}
      { /* End Facebook tags */}
      { /* Twitter tags */}
      <meta name="twitter:creator" content={name ? name : "WindyBot"} />
      <meta name="twitter:card" content={type ? type : "article"} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={description ? trimStringWithSpace(description, 150) : trimStringWithSpace(pageTitle, 150)} />
      { /* End Twitter tags */}
    </Helmet>
  )
}
export default PageTitle;
/*

This is the legacy pagetitle that only changes document title but not meta description etc. We change it to using helment-asyc

import { useEffect } from "react";

const PageTitle = ({ pageTitle, motherTitle, url }: { pageTitle?: string | undefined, motherTitle?: string | undefined, url?: string | undefined }) => {

  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle;
    }
  }, [pageTitle]);
  return (
    <div id="breadcrumb" className="division">
      {
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                {motherTitle && (
                  <li className="breadcrumb-item">
                    <Link to={`/${url}`}>{motherTitle}</Link>
                  </li>
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  {pageTitle}
                </li>
              </ol>
            </nav>
          </div>
        </div>{" "}
                </div>{" "} }
    </div>
  );
};

export default PageTitle;
*/